<template>
  <div class="SimpleSwiper hoverTarget relative" :class="{ fadeDesktopButtons }">
    <div
      ref="parent"
      :style="parentStyle"
      class="simpleSwiper relative w-full overflow-hidden overflow-x-scroll scrolling-touch"
      :class="{
        hasRatio: ratio !== '' && !centered && slideWidth === 100,
      }"
      @scroll="scrolling"
    >
      <div class="ratio">
        <component
          :is="to !== '' ? NuxtLink : 'div'"
          ref="scroller"
          :to="to"
          class="scroller m-auto"
          :style="scrollerStyle"
          @scroll="scrolling(2)"
          @click="$emit('click')"
        >
          <div v-if="centered" :style="centeredStyleFirst" />

          <div
            v-for="(image, imageIndex) in fixedImages"
            :key="image.file + imageIndex"
            class="imageHolder slide relative"
            :class="[
              slideClasses,
              {
                loaded: width > 0,
                centered,
              },
            ]"
            :style="slideStyle"
            @click="$emit('imageClick', imageIndex)"
          >
            <component
              :is="ratio !== '' ? 'RatioBox' : 'div'"
              :ratio="ratio"
              :contain="contain"
              class="relative"
            >
              <div
                v-if="image.linkedVideo"
                class="absolute w-full h-full flex items-center justify-center"
              >
                <div class="inline-block w-32 h-32 bg-darker flex items-center justify-center">
                  <img src="/icons/play-filled-inv.svg" class="w-16 h-16">
                </div>
              </div>
              <nuxt-img
                v-if="image.type === 'image'"
                :preset="preset"
                :provider="provider"
                :loading="(imageIndex === 0 && !lazyFirstImage) ? 'eager' : (lazy || imageIndex > 0 ? 'lazy':'eager')"
                :src="image.file"
                :sizes="sizes"
              />

              <video
                v-else
                autoplay
                muted
                loop
                playsinline
                class="w-full h-full object-cover"
              >
                <source :src="image.file" type="video/mp4">
              </video>
            </component>
          </div>

          <div v-if="centered" :style="centeredStyle" />
        </component>
      </div>
    </div>
    <button
      v-if="fixedImages.length > 1 && closestTo !== 0"
      class="
        button prev
        hidden
        absolute h-40 w-40 left-12 top-1/2 transform -translate-y-1/2 active:-translate-y-1/2
         justify-center
      "
      @click.stop="goPrev"
    >
      <img
        src="/icons/chevron-left.svg"
        class="w-20 h-20 inline-block relative -top-2"
        alt="prev"
      >
    </button>
    <button
      v-if="fixedImages.length > 1 && closestTo < numSlides - 1"
      class="
        button next
        hidden
        absolute h-40 w-40 right-12 top-1/2 transform -translate-y-1/2 active:-translate-y-1/2
         justify-center
      "
      @click.stop="goNext"
    >
      <img
        src="/icons/chevron-right.svg"
        class="w-20 h-20 inline-block relative -top-2"
        alt="next"
      >
    </button>
    <div
      v-if="numSlides > 1 && showDots"
      class="dots absolute flex w-full pb-12 desk:pb-18"
      :class="dotsClass"
    >
      <div
        v-for="i in numSlides"
        :key="i"
        class="w-6 h-6 inline-block rounded-full mx-3 bg-darkest transition transition-transform duration-100"
        :class="{
          'opacity-30': (i - 1 !== closestTo),
          'transform scale-75': (i - 1 !== closestTo) && (i - 1 - closestTo === 1 || i - 1 - closestTo === -1),
          'transform scale-50': (i - 1 !== closestTo) && !(i - 1 - closestTo === 1 || i - 1 - closestTo === -1)
        }"
      />
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
/**
 * Takes an array of images and create a simple side swiper
 *
 * There is also a version that can use any block tag, a, img, div etc: SimpleSwiperAnything
 *
 * @example Example usage
 * <SimpleSwiper :images=['img1.jpg', 'img2.jpg'] alt="Image alt" />
 *
 * @example If we know the image ratio, loading looks nicer
 * <SimpleSwiper :images=['img1.jpg', 'img2.jpg'] ratio="3:2" />
 *
 * :images can also be an ImageAndVideo[] to support video
 *
 * External controlls:
 * Add a ref to the swiper to controll externally
 * <SimpleSwiper ref="swiper"...
 * <button @click="$refs.swiper.goNext()">Next</button>
 */
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { ProductImageAndVideo } from '~/models/product';
const NuxtLink = resolveComponent('NuxtLink');
const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'imageClick', index: number): void;
  (e: 'setSlide', index: number): void;
}>();

type ImageAndVideo = ProductImageAndVideo;

interface Props {
  images: string[] | ProductImageAndVideo[];
  ratio?: string;
  alt?: string;
  to?: string;
  slideWidth?: number;
  slideMargin?: number;
  showDots?: boolean;
  dotsClass?: string; // adds classes to the dots
  centered?: boolean;
  fadeDesktopButtons?: boolean;
  contain?: boolean;
  slideClasses?: string;
  sizes?: string;
  lazy?: boolean;
  lazyFirstImage?: boolean;
  provider?: string // 'norce' | 'storyblok';
  preset?: string;
}

const props = withDefaults(defineProps<Props>(), {
  ratio: '',
  alt: '',
  to: '',
  slideWidth: 100,
  slideMargin: 0,
  showDots: true,
  dotsClass: 'justify-center',
  centered: false,
  fadeDesktopButtons: true,
  contain: false,
  slideClasses: '',
  sizes: 'sm:100vw desk:1260px',
  lazy: true,
  lazyFirstImage: true,
  provider: 'norce',
  preset: 'standard',
});

const width = ref(0);
const currentX = ref(0);

const numSlides = computed(() => {
  return props.images.length;
});

const centeredStyleFirst = computed(() => {
  if (props.centered && width.value > 0) {
    const pad = (100 - props.slideWidth) / 2;
    return {
      width: `calc(${width.value * (pad / 100)}px + ${props.slideMargin}px)`,
    };
  }
  return '';
});

const centeredStyle = computed(()=> {
  if (props.centered && width.value > 0) {
    const pad = (100 - props.slideWidth) / 2;
    return {
      width: `${width.value * (pad / 100)}px`,
    };
  }
  return undefined;
});

const parentStyle = computed(() => {
  if (props.ratio !== '') {
    const ratio = props.ratio.split(':');
    const out = (100 * parseFloat(ratio[1])) / parseFloat(ratio[0]);

    return `--ratio: ${out}%;`;
  }
  return '';
});

const scrollerStyle = computed(() => {
  if (width.value === 0) {
    return {};
  }
  const images = width.value * (props.slideWidth / 100) * numSlides.value;
  const gap = (numSlides.value - 1) * props.slideMargin;
  const pad = props.centered ? width.value * ((100 - props.slideWidth) / 100) : 0;
  const fullW = images + gap + pad;
  return {
    width: `${fullW}px`,
  };
});
const slideStyle = computed(() => {
  if (width.value === 0) {
    return {};
  }
  return {
    width: `${width.value * (props.slideWidth / 100)}px`,
    'margin-right': `${props.slideMargin}px`,
  };
});

const closestTo = computed(() => {
  const w = width.value * (props.slideWidth / 100) + props.slideMargin;
  return width.value > 0 ? Math.round(currentX.value  / w) : 0;
});

const parent = ref<HTMLElement>();
const getParentSize = () => {
  if (parent.value) {
    width.value = parent.value.clientWidth;
  }
};

onMounted(() => {
  setTimeout(() => {
    getParentSize();
  }, 50);
  window.addEventListener('resize', getParentSize);
});
onBeforeUnmount(()=> {
  window.removeEventListener('resize', getParentSize);
});

// navigation
const goPrev = () => {
  if (parent.value) {
    parent.value.scrollLeft -= width.value;
  }
};
const goNext = () => {
  if (parent.value) {
    parent.value.scrollLeft += width.value;
  }
};

const goImage = (index: number) => {
  if (parent.value) {
    const position = width.value * index;
    parent.value.scrollLeft = position;
  }
};

const scrolling = (event: any) => {
  // this should be type Event but not working
  currentX.value = event.target?.scrollLeft || 0;
};

const fixedImages = computed<ImageAndVideo[]>(()=> {
  if (!props.images.length) {
    return [];
  }
  let images = [] as ImageAndVideo[];
  if (typeof props.images[0] === 'string') {
    images = props.images.map((i)=> {
      return {
        file: i,
        type: 'image',
      };
    }) as ImageAndVideo[];
  } else {
    images = props.images as ImageAndVideo[];
  }
  // For a better looking loading, we only show first image until parent width is determined
  if (width.value === 0) {
    return images.slice(0, 1);
  }
  return images;
});

defineExpose({
  goNext,
  goPrev,
  goImage,
});

watch(closestTo, (newVal) => {
  emit('setSlide', newVal);
});

</script>

<style scoped lang="postcss">
.simpleSwiper {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* firefox */

  &.hasRatio {
    &:before {
      display: block;
      content: '';
      padding-top: var(--ratio);
    }
    .ratio {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .scroller {
    display: flex;
    flex-wrap: nowrap;
    .slide {
      &.loaded {
        scroll-snap-align: start;
        &.centered {
          scroll-snap-align: center;
        }
      }
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }
}
.button {

  &.next {
    right: 10px;
  }
}
.dots {
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  opacity: 1;
}

@media (hover: hover) {
  .hoverTarget:not(.fadeDesktopButtons) {
    .button {
      display: block;
      &:disabled {
        opacity: 0.25;
        cursor: default;
      }
    }
  }
  .hoverTarget.fadeDesktopButtons {
    .button {
      display: block;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover {
      .button {
        opacity: 1;
        &:disabled {
          opacity: 0.25;
          cursor: default;
        }
      }
    }
  }
}

</style>
